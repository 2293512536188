import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Markdown from '../../Markdown'
import Button from '../../Button'
import contentfulImage from '../../../lib/contentful-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

function makeCallable(phone) {
  if (!phone) {
    return ''
  }
  return '+41' + phone.substring(1).replace(/ /g, '')
}

const TeamMembersWithDetails = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner tc">
        <h2 className="headline">{props.title}</h2>
        <Markdown className="column--measure-medium center">
          {props.text}
        </Markdown>
        <div className="hm-team">
          {props.teamMembers.map(teamMember => {
            const hasPicture = typeof teamMember.picture !== 'undefined'
            return (
              <div key={teamMember.id} className="hm-team__member">
                {hasPicture ? (
                  <img
                    src={contentfulImage(teamMember.picture.url, {
                      width: 400,
                      quality: 70,
                    })}
                    className="hm-team__member--img"
                    alt={teamMember.picture.description}
                  />
                ) : null}
                <div className="hm-team__member-details">
                  <p className="hm-team__member--name">{teamMember.name}</p>
                  <div className="hm-team__member--contact-details">
                    <a
                      href={`mailto:${teamMember.email}`}
                      className="hm-team__member--contact"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="contact__icon mr2"
                      />
                      {teamMember.email}
                    </a>
                    <a
                      href={`tel:${makeCallable(teamMember.phone)}`}
                      className="hm-team__member--contact"
                    >
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="contact__icon mr2"
                      />
                      {teamMember.phone}
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
          <div className="hm-team__member">
            <div className="hm-team--jobs-box">
              <p>{props.jobsTitle}</p>
              <p>{props.jobsText}</p>
              <Button className="btn btn--primary" link={props.jobsLink}>
                {props.jobsLink.label}
              </Button>
            </div>
          </div>
        </div>
        <div className="hm-team__content">
          <Markdown className="hm-team--content-box">
            {props.textLeftColumn}
          </Markdown>
          <Markdown className="hm-team--content-box">
            {props.textRightColumn}
          </Markdown>
        </div>
      </div>
    </div>
  )
}

TeamMembersWithDetails.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  teamMembers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      picture: PropTypes.shape({
        url: PropTypes.string.isRequired,
        description: PropTypes.string,
      }).isRequired,
    })
  ).isRequired,
  jobsTitle: PropTypes.string,
  jobsText: PropTypes.string,
  jobsLink: linkPropType,
  textLeftColumn: PropTypes.string,
  textRightColumn: PropTypes.string,
}

export default TeamMembersWithDetails
