// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Team from 'components/Contentful/Team'
import TeamMembersWithDetails from 'components/Contentful/TeamMembersWithDetails'
import Locations from 'components/Contentful/Locations'
import Brands from 'components/Contentful/Brands'
import ContactPerson from 'components/Contentful/ContactPerson'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Unser Team - Wir stellen uns vor"
        meta={[
          { name: 'description', content: 'Erfahren Sie hier alles über unsere Wärmepumpen-Profis und wo Sie uns schweizweit finden können. Wir freuen uns auf Sie!' },
          { name: 'keywords', content: 'Team, Mitarbeiter' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='6k8ND1oiZyYSQI0mYYOwqO-cUYTmi772wagmWSq2Sq8A'
          id='Team'
        >
          <Team
            {...{
    "type": "team",
    "id": "cUYTmi772wagmWSq2Sq8A",
    "updatedAt": "2019-04-23T10:08:58.795Z",
    "title": "Kompetent und engagiert",
    "description": "<p>Wir sind Wärmepumpen-Profis und setzen alles daran, unseren Kundinnen und Kunden die optimale Heizungslösung zu verschaffen.</p>\n<p>Wenn wir ein Heiz- oder Energiesystem verwirklichen, sollen auch noch die nächste und die übernächste Generation davon profitieren. Daher sind für uns Wärmepumpen die erste Wahl. Sie sind nicht nur günstig und praktisch, sondern auch umweltfreundlich und zukunftssicher.</p>\n<p>In der Praxis ist ein Heizungsersatz meist eine einmalige Investition. Es gibt viele Produkte und Systeme auf dem Markt – das Angebot ist oft unübersichtlich. Mit uns haben Sie Transparenz und den richtigen Partner. Wir tun unser Bestes, damit Sie am Schluss das richtige System zum fairen Preis installiert haben.</p>\n<p>Wir von den Heizungsmachern haben uns vorgenommen, mit diesen Unzulänglichkeiten aufzuräumen und unseren Kundinnen und Kunden zu einer Wärmepumpe zu verhelfen, an der sie nachhaltig Freude haben. Die vielen guten Rückmeldungen zeigen uns, dass wir auf dem richtigen Weg sind.</p>\n",
    "image": {
        "description": "Gruppenbild des Heizungsmacher Teams",
        "contentType": "image/jpeg",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/33775u15Q4iUsoMGQ0YCCy/b2a1e9e293c27177f81354f956d0dad5/team_2019.jpg"
    },
    "anchorName": "Team"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='6k8ND1oiZyYSQI0mYYOwqO-5vFAuWp6r64s66ouUOKs0y'
          id=''
        >
          <TeamMembersWithDetails
            {...{
    "type": "teamMembersWithDetails",
    "id": "5vFAuWp6r64s66ouUOKs0y",
    "updatedAt": "2021-05-11T07:41:51.041Z",
    "title": "Ihre Wärmepumpen-Expertinnen und Experten",
    "teamMembers": [
        {
            "type": "teamMember",
            "id": "1FTtx0eMOk2q8wcmsMG4We",
            "updatedAt": "2019-08-13T11:45:35.051Z",
            "name": "Thomas Schweingruber",
            "email": "thomas.schweingruber@heizungsmacher.ch",
            "phone": "062 588 06 30",
            "picture": {
                "description": "Teammitglied Thomas Schweingruber",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4mun91DcVOaussCQwSgwW4/fe5a9981716395e3cb91d3baecf4ccd8/thomas_schweingruber_2019.jpg"
            }
        },
        {
            "type": "teamMember",
            "id": "5lWP6deEzCUeKCWqMcuUUe",
            "updatedAt": "2019-08-12T14:23:15.259Z",
            "name": "Tanja Pfluger",
            "email": "tanja.pfluger@heizungsmacher.ch",
            "phone": "062 588 06 31",
            "picture": {
                "description": "Teammitglied Tanja Pfluger",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5j9kBnRlbOIs08gww6wCyC/8d32e5541b553ab021d076fcb239ae15/Tanja.png"
            }
        },
        {
            "type": "teamMember",
            "id": "5XUSxZNrFegw00QMiIquE0",
            "updatedAt": "2019-08-12T14:23:55.304Z",
            "name": "Martin Weber",
            "email": "martin.weber@heizungsmacher.ch",
            "phone": "062 588 06 32",
            "picture": {
                "description": "Teammitglied Martin Weber",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/HeGUigpNGo2WkyOU2gWq6/517778cb0bcb9568964f21fe14aca391/Martin.png"
            }
        },
        {
            "type": "teamMember",
            "id": "1TIPQatjvasoCya2weYmUW",
            "updatedAt": "2021-12-28T09:00:16.034Z",
            "name": "Reto Huber",
            "email": "reto.huber@heizungsmacher.ch",
            "phone": "062 588 06 33",
            "picture": {
                "description": "Mitarbeiterfoto Reto Huber",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6TVNb3oja5x3U0Q4pDLFX2/50a7b00b200603dbe17922e548dccca1/_I5A1613_20220624_fotomtina.jpg"
            }
        },
        {
            "type": "teamMember",
            "id": "1aHfPjucgwwWEEKiE80SAs",
            "updatedAt": "2022-05-06T06:06:41.110Z",
            "name": "Michelle Bolliger",
            "email": "michelle.bolliger@heizungsmacher.ch",
            "phone": "062 588 06 34",
            "picture": {
                "description": "Michelle Bolliger Mitarbeiterfoto",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7KUFnmRcCgboNUqlXQ2pVj/64cd0ec679a04dfefa6d8368b1acfb66/Michelle.png"
            }
        },
        {
            "type": "teamMember",
            "id": "417Cc6eAgw08cq0IeOk0cW",
            "updatedAt": "2019-08-13T20:13:10.066Z",
            "name": "Andreas Hochreutener",
            "email": "andreas.hochreutener@heizungsmacher.ch",
            "phone": "062 588 06 35",
            "picture": {
                "description": "Teammitglieder Andreas Hochreutener",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2VWUIh2nOeOdqReX9GmtJQ/b5f83d1bfadbca9465483ca53ec20f63/_D2A2795_20240322_fotomtina__1_.jpg"
            }
        },
        {
            "type": "teamMember",
            "id": "5SYquvlARSDIIoAx8EAEIA",
            "updatedAt": "2019-08-14T15:38:38.383Z",
            "name": "Marcel Bachmann",
            "email": "marcel.bachmann@heizungsmacher.ch",
            "phone": "062 588 06 37",
            "picture": {
                "description": "Teammitglied Marcel Bachmann",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4g2tFqqL3QmSIKb47EvnZl/35d9cf705b86f99edb83cc77b1d6e2e9/_I5A1562_20220624_fotomtina__1_.jpg"
            }
        },
        {
            "type": "teamMember",
            "id": "5IX6qxHdtPWsVIM4U7Hy7d",
            "updatedAt": "2019-11-20T13:14:12.092Z",
            "name": "Marcel Heer",
            "email": "marcel.heer@heizungsmacher.ch",
            "phone": "062 588 06 39",
            "picture": {
                "description": "Teammitglieder Marcel Heer",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/67VSmsP7dKSeCS5k1PNq0o/07a285b2e8c55dfe6dfe365773ae0775/Marcel_Heer.jpg"
            }
        },
        {
            "type": "teamMember",
            "id": "2OlJZSqqYTaEH5suJC74ky",
            "updatedAt": "2022-02-07T15:51:40.351Z",
            "name": "Stefan Kost",
            "email": "stefan.kost@heizungsmacher.ch",
            "phone": "062 588 06 38",
            "picture": {
                "description": "Teammitglied Stefano Kost",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4aRSzwDMQFTce1r1yjtWnW/c981e3049ab9dc701dd5fa4d792f7d9a/Stefano_Kost.jpg"
            }
        },
        {
            "type": "teamMember",
            "id": "4GxYjUhJWV1Pzk9RTVg4NJ",
            "updatedAt": "2020-04-16T06:52:15.218Z",
            "name": "Tobias Häfliger",
            "email": "tobias.haefliger@heizungsmacher.ch",
            "phone": "062 588 06 56",
            "picture": {
                "description": "Teammitglieder Tobias Häfliger",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1SGD6UBka92KGqYZpWeWBJ/919319badbce1267b3e25b5d33e5d96f/_D2A2887_20240322_fotomtina.jpg"
            }
        },
        {
            "type": "teamMember",
            "id": "dvVHyIfoeCgSviNrU0NxL",
            "updatedAt": "2021-07-01T09:08:40.391Z",
            "name": "Sinem Alagöz",
            "email": "sinem.alagoez@heizungsmacher.ch",
            "phone": "062 588 06 58",
            "picture": {
                "description": "Mitarbeiterfoto Sinem Alagöz",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2K9rfRFDF7hyuzNsMWywm3/dffb7f3375b13df3c8bb12e1a57c15be/Sinem.png"
            }
        },
        {
            "type": "teamMember",
            "id": "5lDXE7gdn8RD0rNI85JQqR",
            "updatedAt": "2024-09-19T04:24:02.008Z",
            "name": "Franz-Josef Schmid",
            "email": "franz.schmid@heizungsmacher.ch",
            "phone": "062 588 06 36",
            "picture": {
                "description": "Portrait Franz Schmid",
                "contentType": "image/webp",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/40vZSBjVFAWOnzq1ELkWbS/8b992d48342d4d7653f44b03dd502dcd/D2A2457_20240820_fotomtina.webp"
            }
        }
    ],
    "jobsTitle": "Jobs bei Heizungsmacher",
    "jobsText": "Du kannst Dir vorstellen, unser Team mit Deinen Erfahrungen und Ideen zu verstärken? Werde Teil von Heizungsmacher!",
    "jobsEmail": "karriere@heizungsmacher.ch",
    "jobsButtonLabel": "Kontaktiere uns",
    "textLeftColumn": "<p><em>Ehrlich und fair</em>\nWir legen alle Kosten auf den Tisch und bieten eine klare Kostenübersicht, welche alle Aufwendungen klar aufzeigt. Wir informieren unabhängig von den Interessen der Hersteller und sagen auch, wenn für einmal eine Wärmepumpe nicht sinnvoll sein sollte.</p>\n<p><em>Kompetent und kommunikativ</em>\nDank der Spezialisierung kennen wir uns mit Wärmepumpen aus wie niemand anders. Ob telefonisch, per Mail oder im Web: wir sind immer ansprechbar und reagieren prompt auf Ihre Anfragen.</p>\n",
    "textRightColumn": "<p><em>Lösungs- und kundenorientiert</em>\nUnsere grösste Freude ist, wenn sich unsere Kunden an einer perfekten Wärmepumpe freuen. Daher suchen wir immer die beste Lösung und setzen alles daran, sie zu verwirklichen.</p>\n<p><em>Engagiert und nachhaltig</em>\nWir stehen voll und ganz hinter der Wärmepumpentechnologie und wollen ihr zum Durchbruch verhelfen. Auch weil wir überzeugt sind, dass sie für die Umwelt am besten ist.</p>\n",
    "jobsLink": {
        "type": "link",
        "id": "1ePAyz3XkMo4Mw4gSyKGIA",
        "updatedAt": "2018-08-09T16:17:22.343Z",
        "label": "Jobs anzeigen",
        "page": {
            "slug": "jobs"
        }
    },
    "text": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='6k8ND1oiZyYSQI0mYYOwqO-3Awofa9YEUAMakqkgEc0g8'
          id='standorte'
        >
          <Locations
            {...{
    "type": "locations",
    "id": "3Awofa9YEUAMakqkgEc0g8",
    "updatedAt": "2024-11-15T12:13:50.117Z",
    "title": "Unsere Standorte",
    "map": {
        "description": "Schweizerkarte mit eingezeichneten Heizungsmacherstandorten in Aarau, Basel, Bern, Schlieren, Solothurn, Sursee, Winterthur und Zürich",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4syPFulSpWKKi4E6US6GMC/e7a5f90d1d6298440ba2ed1d88be27fa/2411_-_Standort-Karte_Heizungsmacher.png"
    },
    "locations": [
        {
            "type": "location",
            "id": "653NOXsIp2g2Ke0O0ogmUq",
            "updatedAt": "2024-03-15T09:23:10.398Z",
            "street": "Aeschenplatz 6",
            "zipCode": 4052,
            "locality": "Basel",
            "phoneNumber": "061 322 32 00"
        },
        {
            "type": "location",
            "id": "1nIMxHV46iMSgBW8gDoXtS",
            "updatedAt": "2019-02-28T12:29:35.618Z",
            "street": "Spitalgasse 28",
            "zipCode": 3011,
            "locality": "Bern",
            "phoneNumber": "031 305 32 00"
        },
        {
            "type": "location",
            "id": "7KGpxy4mghuYfqik4iVG49",
            "updatedAt": "2024-08-28T03:24:32.321Z",
            "street": "Jurastrasse 17",
            "zipCode": 4900,
            "locality": "Langenthal",
            "phoneNumber": "062 923 32 00"
        },
        {
            "type": "location",
            "id": "YLRzK7a1iqhIjZif3jxW4",
            "updatedAt": "2024-10-14T09:43:59.084Z",
            "street": "Hauptgasse 33",
            "zipCode": 4600,
            "locality": "Olten",
            "phoneNumber": "062 822 32 00"
        },
        {
            "type": "location",
            "id": "1THwBEJT6tCR6os3RLVrmE",
            "updatedAt": "2023-07-04T13:54:31.100Z",
            "street": "Zürcherstrasse 39",
            "zipCode": 8952,
            "locality": "Schlieren",
            "phoneNumber": "044 312 32 00"
        },
        {
            "type": "location",
            "id": "5o0IW9QCE7S0VbeF7NemAt",
            "updatedAt": "2024-09-10T15:15:52.585Z",
            "street": "Grabackerstrasse 6",
            "zipCode": 4500,
            "locality": "Solothurn",
            "phoneNumber": "032 623 32 00"
        },
        {
            "type": "location",
            "id": "40zK6hpg1yiS4iSS48UyEA",
            "updatedAt": "2019-02-19T12:42:09.096Z",
            "street": "Wassergrabe 14",
            "zipCode": 6210,
            "locality": "Sursee",
            "phoneNumber": "041 360 32 00"
        },
        {
            "type": "location",
            "id": "13uDvMB8Huo81Wg1eD3VPg",
            "updatedAt": "2024-09-10T15:15:24.954Z",
            "street": "Technoparkstrasse 2",
            "zipCode": 8406,
            "locality": "Winterthur",
            "phoneNumber": "052 243 32 00"
        }
    ],
    "anchorName": "standorte",
    "text": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='6k8ND1oiZyYSQI0mYYOwqO-7rNjuLBYSQ8Sgseuy4i2GA'
          id='verbaende'
        >
          <Brands
            {...{
    "type": "brands",
    "id": "7rNjuLBYSQ8Sgseuy4i2GA",
    "updatedAt": "2021-02-02T10:31:03.938Z",
    "title": "Verbände",
    "description": "<p>Wir engagieren uns aktiv bei den führenden Schweizer Verbänden rund um das Thema Wärmepumpen.</p>\n",
    "logos": [
        {
            "description": "Logo farbig WPSM Wärmepumpen Systemmodul",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1hFANjuUwoMmY6k8koyIY2/b017b7a1398e31e2440d8bfdae9eb154/WPSM_Logo_-_Heizungsmacher_AG.svg"
        },
        {
            "description": "Logo farbig FWS Fachvereinigung Wärmepumpen Schweiz",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/6sBKzwYHpCa4KAkKIW2EE2/671d07ad83b5b3e110f43631f9fe8ba6/FWS_Logo_-_Heizungsmacher_AG-01.svg"
        },
        {
            "description": "Logo farbig Öbu - Der Verband für nachhaltige Wirtschaften",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/3JdPIe5dDVR2H6rk1cmOPV/543a601ea010222e82242b35247e2cc2/__bu_Logo_-_Heizungsmacher_AG-01.svg"
        },
        {
            "description": "Logo farbig Swissolar - der schweizerische Verband für Sonnenenergie",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1DB0UMvLz8g9ljOyusqfLR/f30a65127880b6dfdc8684ca8ae8e34e/Swisssolar_Logo_-_Heizungsmacher_AG.svg"
        },
        {
            "description": "Logo farbig Wir, die Gebäudetechniker",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/5jOjrinc1tgJLgaqtp9WHx/0dd8e8ea14a94a3cf215b47e0b879bff/Geb__udetechniker_Logo.svg"
        },
        {
            "description": "Logo farbig Suissetec - Der Gebäudetechnikverband",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/3MsyP7GgMyadkzMexGFG8g/fadc55692ba6dbb14c9f60dca258f391/Suissetec_Logo_-_Heizungsmacher_AG.svg"
        },
        {
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1FD8ynKTqE8GFy5RYKC46i/29e50740fd742fbac74601cd130ea1ef/Solarprofil_Logo_farbig-01.svg"
        }
    ],
    "anchorName": "verbaende",
    "backgroundColor": "grau"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='6k8ND1oiZyYSQI0mYYOwqO-g9rafUfvTGMY86W4AGog4'
          id=''
        >
          <ContactPerson
            {...{
    "type": "contactPerson",
    "id": "g9rafUfvTGMY86W4AGog4",
    "updatedAt": "2019-08-13T12:02:23.060Z",
    "name": "Tanja Pfluger",
    "job": "<p>Für ihre Fragen da\nVerkaufsberaterin</p>\n",
    "email": "tanja.pfluger@heizungsmacher.ch",
    "image": {
        "description": "Teammitglieder Tanja Pfluger",
        "contentType": "image/jpeg",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4qJrIqa0ut2mT3igTiUUtu/5760a0c6fd68522f0fa2f78f892e8157/Team_Fotos_TP_quer_4.jpg"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
