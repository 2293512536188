import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Button from '../../Button'
import Markdown from '../../Markdown'

const Team = props => {
  const button = props.link ? (
    <p className="tc">
      <Button className="btn--primary" link={props.link}>
        {props.link.label}
      </Button>
    </p>
  ) : null

  return (
    <Fragment>
      <div className="section section--padded-small">
        <div className="section__inner">
          {props.image && props.image.url ? (
            <div className="hm-team__group-img">
              <img src={props.image.url} alt={props.image.description} />
            </div>
          ) : null}
          <h2 className="headline tc">{props.title}</h2>
          <Markdown className="column--measure-medium center tc">
            {props.description}
          </Markdown>
          {button}
        </div>
      </div>
    </Fragment>
  )
}

Team.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  link: linkPropType,
}

export default Team
