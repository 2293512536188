import React from 'react'
import PropTypes from 'prop-types'
import contentfulImage from 'lib/contentful-image'
import Markdown from 'components/Markdown'
import iconMail from './email-icon.svg'

function toCallablePhoneNumber(number) {
  if (number[0] === '0') {
    return `+41 (0) ${number.slice(1)}`
  }
  return number
}

const ContactPerson = props => {
  const imageUrl = contentfulImage(props.image.url, {
    width: 500,
    quality: 75,
  })

  const phoneNumber = props.phoneNumber ? (
    <a href={`tel:${toCallablePhoneNumber(props.phoneNumber)}`}>
      {props.phoneNumber}
    </a>
  ) : null

  return (
    <div className="section section--padded-big">
      <div className="section__inner">
        <div className="media media--direction">
          <div
            className="contact-person__img"
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          />
          <div className="media__article contact-person__details">
            <h2 className="headline tc">{props.name}</h2>
            <Markdown className="tc">{props.job}</Markdown>

            {phoneNumber}
            {props.email ? (
              <a href={`mailto:${props.email}`}>
                {props.email}
                <br />
                <div className="contact-person__icon">
                  <img src={iconMail} />
                </div>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

ContactPerson.propTypes = {
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
}

export default ContactPerson
